<template>
  <div class="table-container">
    <b-table
      :data="items"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <template slot-scope="props">
        <b-table-column
          field="index"
          label="Index"
        >{{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}</b-table-column>

        <b-table-column field="id" label="Identifier" sortable>{{ props.row.id }}</b-table-column>
        <b-table-column field="date" label="Date" sortable>{{ props.row.date }}</b-table-column>
        <b-table-column field="quantity" label="Quantity (pcs)" sortable>{{ props.row.quantity }}</b-table-column>
        <b-table-column
          field="product_name"
          label="Product name"
          sortable
        >{{ props.row.product_name }}</b-table-column>
        <b-table-column
          field="hardware_revision"
          label="Hardware revision"
        >{{ props.row.hardware_revision }}</b-table-column>
        <b-table-column
          field="assembly_variant"
          label="Assembly variant"
        >{{ props.row.assembly_variant }}</b-table-column>
        <b-table-column field="manufacturer" label="Manufacturer" sortable>{{ props.row.manufacturer }}</b-table-column>
       <b-table-column
          field="serial_number_start"
          label="Serial number START"
        >{{ props.row.serial_number_start }}</b-table-column>
        <b-table-column
          field="serial_number_stop"
          label="Serial number STOP"
        >{{ props.row.serial_number_stop }}</b-table-column>
        <b-table-column
          field="action"
        >
          <b-button v-on:click="downloadCSV(props.row)"
            type="is-link"
            icon-left="download"
            size="is-small"
            title="Download barcode csv for printer"
          >CSV</b-button>
        </b-table-column>

      </template>

      <template slot="bottom-left">
        <b-button tag="router-link"
                  :to="{ name: 'batch-add' }"
                  type="is-success"
                  icon-left="plus-box"
          >Add Batch</b-button>

        <b-button
          v-on:click="fetch"
          type="is-info"
          icon-left="refresh"
          outlined
          style="margin-left: 100px"
        >Refresh data</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'batch',
  data() {
    return {
      items: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'date',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.offset = (this.page - 1) * this.perPage;
      const res = await this.$http.get('api/v1/batch', {
        params: {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit: this.perPage,
          offset: this.offset,
        },
      });
      this.items = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    downloadCSV(batch) {
      window.open(`/api/v1/batch/generate-barcode-csv?filename=${batch.id}-barcode.csv&serial_number_start=${batch.serial_number_start}&serial_number_stop=${batch.serial_number_stop}`, '_blank');
    },
  },
};
</script>
